import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './Navbar';
import QrCodeScannerBar from './QrCodeScannerBar';

const Layout = ({ children}) => (
    <Fragment>
      <Helmet htmlAttributes={{ lang: 'en',  }}/>
      <Navbar />
        <main>
        {children}
        </main>
        <QrCodeScannerBar />
    </Fragment>
)

export default Layout;
import React from 'react';
import { Link } from 'gatsby';
import camera from '../../static/assets/images/camera.png';

const QrCodeScannerBar = () => (
    <div className="footer">
        <Link className="camera-link" activeClassName="active" to="/scanner">
            <img src={camera} alt="Camera Icon" />
        </Link>
    </div>
)

export default QrCodeScannerBar;
const EXPERIENCES_KEY = "experiences";

const localStorage = typeof window !== `undefined` ? window.localStorage : null;

const EXPERIENCES = {
    SOLAR_SYSTEM: "Solar System",
    EARTH_WITH_ISS: "Earth",
    MARS: "Mars",
    ISS: "ISS"
};

const experienceInfo = [
    {
        name: EXPERIENCES.SOLAR_SYSTEM,
        route: "/experience-pages/solarsystem",
        src: "/experiences/solarsystem/solarsystem.html",
    },
    {
        name: EXPERIENCES.EARTH_WITH_ISS,
        route: "/experience-pages/earth-iss",
        src: "/experiences/earth-iss/index.html",
    },
    {
        name: EXPERIENCES.MARS,
        route: "/experience-pages/mars",
        src: "/experiences/mars/index.html",
    },
    {
        name: EXPERIENCES.ISS,
        route: "/experience-pages/iss",
        src: "/experiences/iss/index.html",
    }
];

function getExperiences() {
    const storedExperiences = localStorage
        ? JSON.parse(localStorage.getItem(EXPERIENCES_KEY))
        : null;
    if (storedExperiences && storedExperiences.length) {
        return experienceInfo.map((experience) => {
            const storedExperience = storedExperiences.filter(
                (storedExperience) => {
                    return experience.name === storedExperience.name;
                }
            );
            // we should only add explicitly defined state that we want to persist
            if (storedExperience && storedExperience.length) {
                experience["visited"] = storedExperience[0].visited || false;
            }
            return experience;
        });
    } else {
        return experienceInfo;
    }
}

function getExperienceIdx(name) {
    if (!name) {
        return -1;
    }
    return experienceInfo.map(({ name }) => name).indexOf(name);
}

function getExperienceInfo(name) {
    const experienceIdx = getExperienceIdx(name);
    if (experienceIdx === -1) {
        return {};
    }
    return experienceInfo[experienceIdx];
}

function markExperienceAsVisited(name) {
    const experiences = getExperiences();
    const experienceIdx = getExperienceIdx(name);
    if (experienceIdx === -1) {
        return;
    }
    experiences[experienceIdx].visited = true;
    if (localStorage) {
        localStorage.setItem(EXPERIENCES_KEY, JSON.stringify(experiences));
    }
}

// Change this to true will enable localStorage reset in Production mode, if required
const ResetOnGetStartedButton = false;

function resetStore() {
    if (
        localStorage &&
        (ResetOnGetStartedButton || process.env.NODE_ENV === "development")
    ) {
        localStorage.clear();
    }
}

export {
    EXPERIENCES,
    getExperienceInfo,
    getExperiences,
    markExperienceAsVisited,
    resetStore
};

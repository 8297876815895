import React, { useMemo } from "react";
import { Link } from "gatsby";
import gsclogo from "../../static/assets/images/gsclogo-inverse.png";
import { getExperiences } from "./store";

const Navbar = () => {
    function toggleSideNav() {
        const sidebar = document.getElementById("navbarSideNav");
        sidebar.classList.toggle("open");
    }
    const experiences = useMemo(() => getExperiences(), []);

    return (
        <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <img
                        src={gsclogo}
                        alt="Glasgow Science Centre logo"
                        aria-hidden="true"
                    />
                </Link>
                <button
                    className="navbar-toggler"
                    id="toggleSideNav"
                    data-bs-target="#navbarSideNav"
                    aria-controls="navbarSideNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={toggleSideNav}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="offcanvas-collapse" id="navbarSideNav">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {experiences.map(
                            ({ name, route, visited }, idx) =>
                                visited && (
                                    <li key={idx} className="nav-item">
                                        <Link
                                            className="nav-link"
                                            activeClassName="active"
                                            to={route}
                                        >
                                            {name}{" "}
                                        </Link>
                                    </li>
                                )
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
